import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import ProductPage from './components/ProductPage'
import HomePage from './components/HomePage'
import NotFoundPage from './components/NotFoundPage'
import ProductResults from './components/ProductResults'
import ProductCategory from './components/ProductCategory'

function App() {
  return (
    <>
      <Header />
      <main className='flow content-grid'>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/product/*' element={<ProductPage />} />
          <Route path='/results' element={<ProductResults />} />
          <Route path='/category/*' element={<ProductCategory />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </main>
    </>
  )
}

export default App
