import React from 'react'
import styled from 'styled-components'
import logoMain from '../assets/logo-main.png'
import ProductSearchBar from './ProductSearchBar'

const StyledHeader = styled.header`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);

  > h1 {
    user-select: none;
  }
`

const Logo = styled.img`
  height: 80%;
  user-select: none;
`

const Header = () => {
  return (
    <StyledHeader>
      <Logo src={logoMain}></Logo>
      <h1>PriceMatcher</h1>
      <ProductSearchBar
        placeholder='Search products'
        width={500}
      ></ProductSearchBar>
    </StyledHeader>
  )
}

export default Header
