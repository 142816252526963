export const transformNaturalNumber = (
  value: string,
  defaultValue?: number,
) => {
  const integerValue = parseInt(value)
  if (isNaN(integerValue) || integerValue < 0) {
    return defaultValue ?? null
  }
  return integerValue
}
