import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useAPI from '../hooks/useAPI'
import styled from 'styled-components'
import { ListResults, SimpleProductResult } from '../types/backend'
import { Dropdown, DropdownItemProps } from 'semantic-ui-react'

const SEARCH_DEBOUNCE = 350
const MAX_RESULTS = 10

export interface ProductSearchBarProps {
  placeholder?: string
  width?: number | string
}

const StyledDropdown = styled(Dropdown)<{ width: number | string }>`
  width: ${props =>
    typeof props.width === 'string' ? props.width : props.width + 'px'};

  border-radius: 10px !important;

  .menu {
    border-radius: 0 0 10px 10px !important;
    max-height: 70vh !important;

    .ui.image {
      mix-blend-mode: multiply;
    }
  }
`

const ProductSearchBar = ({ width = 'auto' }: ProductSearchBarProps) => {
  const { data, updateConfig } = useAPI<ListResults<SimpleProductResult>>()
  const [searchParams] = useSearchParams()
  const [query, setQuery] = useState('')
  const navigate = useNavigate()

  // Debounce product search api calls
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (query === '') return
      updateConfig({ url: 'search/', params: { query } })
    }, SEARCH_DEBOUNCE)
    return () => clearTimeout(timeoutId)
  }, [query, updateConfig])

  const getResultsParams = useCallback(() => {
    searchParams.set('query', query)
    searchParams.set('offset', '0')
    return searchParams.toString()
  }, [query, searchParams])

  const options: DropdownItemProps[] = useMemo(() => {
    if (!data?.results?.length) {
      return []
    }
    const results = data.results.map<DropdownItemProps>(result => ({
      key: result.model,
      text: result.name,
      value: result.model,
      image: {
        avatar: false,
        src: result.image,
        verticalAlign: 'middle',
      },
    }))

    return [
      ...results,
      {
        key: -1,
        text:
          data.count > MAX_RESULTS
            ? `Show all ${data.count} results`
            : 'Showing all results',
        disabled: data.count <= MAX_RESULTS,
        selected: false,
        onClick: () =>
          data.count > MAX_RESULTS &&
          navigate({
            pathname: '/results',
            search: getResultsParams(),
          }),
      },
    ]
  }, [data, getResultsParams, navigate])

  return (
    <StyledDropdown
      width={width}
      placeholder='Search'
      selection
      search={opts => opts}
      clearable
      selectOnNavigation={false}
      selectOnBlur={false}
      wrapSelection={false}
      options={[...options]}
      onSearchChange={({ target }) =>
        setQuery((target as HTMLInputElement).value)
      }
      onChange={(_, data) =>
        data.value &&
        navigate({
          pathname: '/product/' + encodeURIComponent(data.value as string),
        })
      }
    />
  )
}

export default ProductSearchBar
