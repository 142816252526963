import React, { useEffect } from 'react'
import { Axis, XYChart, Tooltip, AreaSeries } from '@visx/xychart'
import useAPI from '../hooks/useAPI'
import { PriceData } from '../types/backend'

type GraphData = Pick<PriceData, 'date' | 'price'>

export interface PriceHistoryGraphProps {
  model?: string
}

const PriceHistoryGraph = ({ model }: PriceHistoryGraphProps) => {
  const { data, updateConfig } = useAPI<{ [source: string]: GraphData[] }>()

  useEffect(() => updateConfig({ url: `product/${model}/history/` }), [model])

  const accessors = {
    xAccessor: (d?: GraphData) => d?.date,
    yAccessor: (d?: GraphData) => d?.price,
  }

  if (!data) return <></>

  return (
    <XYChart height={300} xScale={{ type: 'band' }} yScale={{ type: 'linear' }}>
      <Axis orientation='bottom' />
      <Axis orientation='left' numTicks={5} />
      {Object.entries(data).map(([source, graphData], i) => (
        <AreaSeries key={i} dataKey={source} data={graphData} {...accessors} />
      ))}
      <Tooltip<GraphData>
        snapTooltipToDatumX
        showVerticalCrosshair
        showSeriesGlyphs
        renderTooltip={({ tooltipData, colorScale }) => {
          if (!tooltipData?.nearestDatum || !colorScale) return <></>
          return (
            <div>
              {Object.entries(tooltipData.datumByKey).map(
                ([source, datum], i) => {
                  return (
                    <div key={i}>
                      <span style={{ color: colorScale(source) }}>
                        {source}
                      </span>
                      <span>${datum.datum.price}</span>
                    </div>
                  )
                },
              )}
            </div>
          )
        }}
      />
    </XYChart>
  )
}
export default PriceHistoryGraph
