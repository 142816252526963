import React, { useEffect, useMemo, useState } from 'react'
import {
  Pagination,
  Icon,
  Dropdown,
  DropdownItemProps,
} from 'semantic-ui-react'
import styled from 'styled-components'

const DEFAULT_OPTIONS = [10, 20, 50, 100]
const DEFAULT_OPTION = 10

export interface PageEvent {
  activePage: number
  itemsPerPage: number
}

const StyledPaginatorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
`

const StyledDropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
`

const StyledPagination = styled(Pagination)`
  border: none !important;
`

const StyledDropdown = styled(Dropdown)`
  min-width: 100px !important;

  .menu {
    max-height: 75vh !important;
  }

  &:not(.active) {
    border-color: transparent !important;
  }
`

export interface PaginatorProps {
  onPageEvent: (event: PageEvent) => void
  itemsPerPageOptions?: number[]
  defaultItemsPerPage: number
  itemCount: number
}

const Paginator = ({
  onPageEvent = () => {},
  itemsPerPageOptions = DEFAULT_OPTIONS,
  defaultItemsPerPage,
  itemCount,
}: PaginatorProps) => {
  const [itemsPerPage, setItemsPerPage] = useState(
    defaultItemsPerPage ?? itemsPerPageOptions.at(0) ?? DEFAULT_OPTION,
  )
  const [activePage, setActivePage] = useState(1)

  useEffect(() => {
    if (itemsPerPageOptions.includes(itemsPerPage)) return
    const newItemsPerPage = itemsPerPageOptions.at(0) ?? DEFAULT_OPTION
    setItemsPerPage(newItemsPerPage)
  }, [itemsPerPage, itemsPerPageOptions])

  useEffect(() => {
    onPageEvent({ activePage, itemsPerPage })
  }, [activePage, itemsPerPage, onPageEvent])

  const handlePageChange = (value: number | string | undefined) => {
    if (value === undefined) return
    const pageNumber = typeof value === 'string' ? parseInt(value) : value
    if (isNaN(pageNumber)) return
    setActivePage(pageNumber)
  }

  const handleItemPerPageChange = (value: number) => {
    setActivePage(1)
    setItemsPerPage(value)
  }

  const options = useMemo(
    () =>
      itemsPerPageOptions.map<DropdownItemProps>((n, i) => ({
        key: i,
        text: n,
        value: n,
      })),
    [itemsPerPageOptions],
  )

  return (
    <StyledPaginatorContainer className='ui menu'>
      <StyledPagination
        activePage={activePage}
        totalPages={Math.ceil(itemCount / itemsPerPage)}
        onPageChange={(_, data) => handlePageChange(data.activePage)}
        ellipsisItem={{
          content: <Icon name='ellipsis horizontal' />,
          icon: true,
        }}
        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
        prevItem={{ content: <Icon name='angle left' />, icon: true }}
        nextItem={{ content: <Icon name='angle right' />, icon: true }}
      />
      <StyledDropdownContainer>
        <span>Products per page:&nbsp;</span>
        <StyledDropdown
          selection
          value={itemsPerPage}
          options={options}
          onChange={(_, data) => handleItemPerPageChange(data.value as number)}
        />
      </StyledDropdownContainer>
    </StyledPaginatorContainer>
  )
}

export default Paginator
